import React, { Fragment, PureComponent } from 'react'
import { Layout, Jumbotron, Sides, Marks } from '../../../components'
import '../../style.less'

class TraditionalFavourites extends PureComponent {
  componentDidMount () {
    document.body.scrollTop = 0
  }

  render () {
    return (
      <Fragment>
        <Layout
          title={'Traditional Favourites'}
          location={this.props.location}
        >
          <Jumbotron
            heading={'Traditional Favourites\'s'}
            image={'burger.png'}
          />
          <div className='container'>
            <Marks />
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <h2 className='para-h2'>Freshly Baked Jacket Potatoes <i>Available from 12pm to 3pm</i></h2>
                <b >Served with Honey &amp; Mustard dressed Salad and Homemade Coleslaw</b>
                <div className='para-h4'>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <div>Mature Cheddar Cheese <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span><div className='pull-right'>£6.95</div></div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <div>Baked Beans <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span><div className='pull-right'>£6.95</div></div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <div>Mature Cheddar Cheese <br className='brnodisplay'></br>&amp; Baked Beans <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span><div className='pull-right'>£7.45</div></div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <div>Homemade Beef Chilli Con Carne <i style={{ color: '#ff7700', fontSize: '14px' }}><br className='brnodisplay'></br>Medium Spicy</i> <span className='gf-mark'>GF</span><div className='pull-right'>£7.95</div></div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <div>Prawn <span className='gf-mark'>GF</span><div className='pull-right'>£7.95</div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md- 12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Hand Carved Gammon Ham, Egg &amp; Chips <span className='gf-mark'>GF</span></h3>
                  <h4>Hand Carved Smoked Gammon Ham Served With Two Free Range Fried Eggs &amp; Chips. <p className='pull-right'>£12.45</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Homemade Curry</h3>
                  <h4>Made To Our Own Recipe, Served With Rice, Naan Bread &amp; Papadum. <br/><i style={{ color: '#ff7700' }}>Please Ask For Today&lsquo;s Choices</i><p className='pull-right'>£15.95</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Homemade Beef Chilli Con Carne <span className='gf-mark'>GF</span></h3>
                  <h4>Made To Our Own Recipe, Served With Rice, Nachos &amp; Sour Cream.<br /><i style={{ color: '#ff7700' }}>Medium Spicy</i><p className='pull-right'>£13.25</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Beer Battered Cod</h3>
                  <h4>Made to our own recipe, served with chips, Mushy Peas, Homemade Tartar sauce &amp; a wedge of Lemon <br /><i style={{ color: '#ff7700' }}>May contain small bones</i><p className='pull-right'>£14.00</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Farmhouse Sausage &amp; Mash </h3>
                  <h4>Locally Sourced Sausages, Served With Buttery Mash, Caramelised Onion Gravy &amp; Seasonal Vegetables.<p className='pull-right'>£14.25</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Homemade Pie</h3>
                  <h4>Made To Our Own Recipe, Served With Either Chips Or Buttery Mash &amp; Seasonal Vegetables.<br/><i style={{ color: '#ff7700' }}>Please Ask For Today&lsquo;s Choices</i><p className='pull-right'>£14.95</p></h4>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Build Your Own Burger&apos;s</h3>
                  <b>All Burgers Are Served In A Toasted Brioche Bap With Lettuce, Tomato &amp; Red Onion &amp; Gherkins. Served With Beer Battered Onion Rings, Chips &amp; Homemade Coleslaw.</b>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <h4 className='mt-0'>6oz - Steak Beef Burger<div className='pull-right' style={{ fontSize: '18px' }}>£14.95</div>
                      </h4>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <h4 className='mt-0'>6oz – Miami Burger <span className='vg-mark'>VG</span><div className='pull-right' style={{ fontSize: '18px' }}>£14.95</div></h4>
                    </div>
                  </div>
                  <h4>Additional Toppings - Add As Many As You Like!!</h4>
                  <div className='row'>
                    <div className='col-md-12 col-xs-12'>
                      <div className='row'>
                        <div className='col-md-12 col-xs-12'>
                          <div>Mature Cheddar Cheese<div className='pull-right'>£0.75</div></div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 col-xs-12'>
                          <div>Brie<div className='pull-right'>£1.00</div></div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 col-xs-12'>
                          <div>Stilton<div className='pull-right'>£1.00</div></div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 col-xs-12'>
                          <div>Free Range Egg <div className='pull-right'>£0.60</div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 col-xs-12'>
                          <div>Sautéed Mushrooms<div className='pull-right'>£0.60</div></div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 col-xs-12'>
                          <div>Smoked Back Bacon<div className='pull-right'>£0.60</div></div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 col-xs-12'>
                          <div>Homemade BBQ pulled pork <div className='pull-right'>£1.50</div></div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 col-xs-12'>
                          <div>Homemade Chilli Con Carne <i style={{ color: '#ff7700', fontSize: '14px' }}><br className='brnodisplay'></br>Medium Spicy</i><div className='pull-right'>£1.50</div></div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 col-xs-12'>
                          <div>Double up on your Burger for<div className='pull-right'>£4.00</div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Sides />
          </div>
        </Layout>
      </Fragment >
    )
  }
}

export default TraditionalFavourites
